import React from "react";
import routes from "src/core/common/routes";
import {makeProductsPage} from "src/core/pages/productsPagesFactory";
import ProductListSchema from "src/core/seo/components/ProductListSchema";
import {getHomeServerSideProps, getHomeStaticProps} from "src/server/preloader/homePage";
import * as Sentry from "@sentry/nextjs";
import {getTags} from "src/server/preloader/utils";
import Metadata from "src/core/seo/components/Metadata";
import {isClient} from "src/server/utils/isClient";

const BasePage = makeProductsPage(() => ({pathname: routes.home}));

export default function ({metadata, ...props}) {
  return (
    <>
      {!isClient && <Metadata {...metadata} />}
      <ProductListSchema path={"store-selection/"} />
      <BasePage {...props} />
    </>
  );
}

export async function ssr_getServerSideProps(context) {
  Sentry.setTags(getTags("getServerSideProps", context));

  return getHomeServerSideProps(context);
}

export async function isr_getStaticProps(context) {
  Sentry.setTags(getTags("getStaticProps", context));

  return getHomeStaticProps(context);
}
export async function isr_getStaticPaths() {
  return {
    paths: [],
    fallback: "blocking",
  };
}
